.add_campaign{
  border: 1px solid #136CC3;
  background-color: #136CC3 !important;
  color: #fff !important;
}

.title{
  color: #F95C73;
}

.campaigns-list{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  overflow-y: auto;
  margin-top: 24px;;
  width: 100%;
}

.mp_campaign_cards{
  height: 232px;
  width: 420px;
  background-color: honeydew;
  border-radius: 10px;
  padding: 4px 20px;
  border: 2px solid #5011A2;
}

.card_contents{
  font-weight: 500;
}

.campaign_title, .campaign_desc, .campaign_company{
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden; 
}

.c_view_btn_cont, .modal-submit-campaign{
  display: flex;
  justify-content: flex-end;
}

.campaign_view_btn, .submit_campaign_btn{
  background-color: #136CC3 !important;
  color: #fff !important;
  margin-left: 48px;
}

.campaign_modal_title{
  margin-bottom: 36px;
}

.campaign-modal-input-cont{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.campaign-modal-input-cont input{
  width: 100%;
}

.upload_btn{
  background-color: #008170 !important;
  color: #fff !important;
}

.video_cards{
  width: 360px;
  border: 1px solid #136CC3;
  border-radius: 4px;
}

.video_cards:hover{
  cursor: pointer;
  background-color: lightgray;
}

.video_content{
  padding: 0 6px;
}

.video_action_btn_cont{
  display: flex;
  margin-top: 8px;
  justify-content: space-evenly;
  margin-bottom: 8px;
}

.content_approve_btn{
  background-color: #008170 !important;
  color: #fff !important;
}

.content_reject_btn{
  background-color: #F95C73 !important;
  color: #fff !important;
}

.content_comment_btn{
  background-color: #136CC3 !important;
  color: #fff !important;
}

.modal-submit-comment{
  display: flex;
  
  justify-content: flex-end;
}

.comment-list-cont{
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* margin-top: 8px; */
  max-height: 380px;
  overflow-y: auto;
  margin-bottom: 24px;
}

.comment-list-item-user{
  margin: 0;
}

.comment-list-cont::-webkit-scrollbar {
  width: 8px;
}

.comment-list-cont::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.comment-list-cont::-webkit-scrollbar-thumb {
  background: #888; 
}

.comment-list-item-comment{
  font-weight: 500;
}

.modal-submit-btn{
  margin: 0 !important;
}

.modal-submit-comment{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;
}

.back_btn_cont{
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.back_btn{
  background-color: #4E11A2 !important;
  color: #fff !important;
  height: 36px;
  width: 36px;
}