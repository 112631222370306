form.csv-upload-form{
    display: flex;
    flex-direction: column;
}

form.csv-upload-form button[type="submit"]{
    /* margin: 3rem auto 0 auto; */
    /* display: flex; */
    /* flex:0; */
    margin-right: auto;
    margin-top: 20px;
    margin-left: 20px;
}

div.csv-input-container{
    display: flex;
    /* justify-content: center; */
    margin-top: 20px;
    padding-left: 20px;
}
div.csv-input-container > h3{
    /* margin-right: 5rem; */
}
div.csv-input-container input{
    border:none;
}

.error-box{
    color: red;
}