.cardspace {
    margin-top: 40px;
}

.image-wrapper {
    position: relative;
}

.image-uploaded {
    height: 51px;
    /* position: absolute;
    top: 8px; */
    /* right: 0px; */
}

.image-btn {
    height: 40px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 200px;
    opacity: 0;
    cursor: pointer;
}

.remove-img {
    transform: rotate(45deg);
    width: 15px;
    position: absolute;
    right: -15px;
    top: 13px;
}

.remove-vid {
    right: -19px;
}


.creative-consultancy .map-box .card-body {
    background-color: #ffffff;
    color: #000000;
}

.id-card {
    border: 7px solid #ffffff;
    border-radius: 10px;
    padding: 2px;
    margin-top: 25px;
    max-height: 450px;
    overflow: auto;
}

.id-card .card {
    margin-top: 20px;
    margin-bottom: 60px;
}

.id-card .card .icon {
    background-color: #136cc3;
}

.id-card .card:last-child {
    margin-bottom: 0;
}

.id-card .card-body {
    border-radius: 10px;
    margin-bottom: 0px;
    padding: 40px 0px 10px 0px;
}

.id-card .card.message-content {
    min-height: inherit;
    min-width: 186px;
}

.id-card .badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: #000000;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.bg-white {
    background-color: #ffffff !important;
}

.score-box {
    /* border: 15px solid #ffffff;
    border-radius: 100%;
    min-height: 140px;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    position: relative; */
    border: 10px solid #ffffff;
    border-radius: 97%;
    min-height: 89px;
    text-align: center;
    font-size: 25px;
    min-width: 87px;
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 2px;
    position: relative;
}

.score-box .count {
    width: 100%;
    /* text-align: left; */
    /* padding-right: 20px; */
    padding: 0 20px 5px 0;
}

.score-box .count-score {
    width: 100%;
    /* text-align: right; */
    padding-left: 26px;
    font-size: 14px;
    color: #000000;
}

/* .score-box .count:after {
    width: 73px;
    height: 10px;
    content: "";
    left: 0;
    right: 0;
    background-color: #ffffff;
    position: absolute;
    display: block;
    -webkit-transform: rotate(137deg);
    transform: rotate(137deg);
    top: 32px;
} */

.score-box .count:after {
    width: 90px;
    height: 10px;
    content: "";
    left: 0;
    right: 0;
    background-color: #ffffff;
    position: absolute;
    display: block;
    -webkit-transform: rotate(153deg);
    transform: rotate(153deg);
    top: 32px;
}

@media only screen and (max-width: 1440px)  {
    .score-box .count:after {
        width: 84px;
    }
}

@media only screen and (max-width: 768px)  {
    .score-box .count:after {
        width: 75px;
    }
}

@media only screen and (max-width: 1024px)  {
    .score-box .count:after {
        width: 73px;
    }
}

.score-box .count span {
    font-size: 14px;
}

.title-name {
    text-align: center;
}

.id-card .badge-shape2 {
    background-color: #F59533;
}

/* .comments .badge-shape2:before {
    content: "";
    left: 20px;
    bottom: -18px;
    width: 15px;
    height: 26px;
    border-left: 15px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #51B0D0;
    border-radius: 0px 50px 20px 160px;
    position: absolute;
    display: block;
    background-color: #51B0D0;
    z-index: 1;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(0deg) skewY(14deg) !important;
} */

.comments-side .badge-shape2:before {
    bottom: -12px;
}

.comments .badge-shape-right.badge-shape2:before {
    right: 20px;
    left: auto;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg) skewY(14deg) !important;
}

.comments .card .card-body {
    padding: 18px 10px 18px 10px;
    background-color: #51B0D0;
}

.comments .card .right-answer-arrow {
    width: 25px;
    height: 30px;
    position: relative;
    top: 10px;
    left: -175px;
    background-color: #51B0D0;
    border: none;
    border-top-left-radius: 100px;
}

.comments-side .card .card-body {
    padding: 6px 10px 6px 10px;
}

.comments-side .card .left-answer-arrow {
    width: 20px;
    height: 25px;
    position: relative;
    top: 10px;
    right: -80px;
    background-color: #51B0D0;
    border: none;
    border-top-right-radius: 100px;
}

.comments .card .card-body h6 {
    margin: 0;
    color: #000;
}

.comments .card {
    margin-bottom: 30px;
}

.demographic div .highcharts-container {
    /* width: 200px!important; */
    /* height: 200px!important; */
}