/* .heading{
    color: #fb6d6a;
    font-weight: 600;
    margin: 0;
    font-size: 20px;
    margin-left:20px;
}

.flex{
    display: flex;
}
.justify-center{
    justify-content: center;
}
.justify-between{
    justify-content: space-between;
}
.justify-round{
    justify-content: space-around;
}
.align-center{
    align-items: center;
} */
.channel-selection-modal{
    width: calc(100% - 40px);
    max-width:800px;
    /* max-width: 700px; */
    height: 500px;
    /* background-color: rgb(156 6 149); */
    background-color: white;
    /* overflow: hidden; */
    /* position: relative; */
    padding: 10px 20px;
    color:black;
    border: none;
    overflow: hidden;
}
.channel-selection-modal .channel-list-table{
    position: relative;
}
.channel-selection-modal .channel-list-table thead{
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
}

.channel-selection-modal .channel-list-table tbody > tr:not(:hover):nth-child(odd){
    background:   #f4f6f6;
}

.channel-list-body{
    /* height: 400px; */
    position: relative;
}
.channel-selection-modal h3{
    padding: 0;
    margin: 0;
}
.channel-selection-modal .close-button{
    padding: 0 !important;
    margin:0;
}

.channel-selection-modal .MuiTableContainer-root{
    overflow: auto;
    height: calc(100% - 81px);
}
.channel-selection-modal table{
    position: relative;
}

.channel-list-table{
    color: black;
}
.channel-list-table tr, .channel-list-table th{
    color: black;
    border: none;
}
.channel-list-table td{
    border: none;
}
.channel-list-table th{
    /* border: 1px solid wheat; */
    border: none;
}
.channel-list-table .channel-row{
    cursor: pointer;
}
.channel-table-head th{ 
    font-weight: bold;
    height: 40px;
}