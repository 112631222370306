.heading{
    color: #fb6d6a;
    font-weight: 600;
    margin: 0;
    font-size: 20px;
}

.flex{
    display: flex;
}
.flex-col{
    flex-direction: column;
}
.flex-row{
    flex-direction: row;
}
.flex-row-imp{
    flex-direction: row !important;
}
.justify-center{
    justify-content: center;
}
.justify-between{
    justify-content: space-between;
}
.justify-round{
    justify-content: space-around;
}
.align-center{
    align-items: center;
}
.flex-wrap{
    flex-wrap: wrap;
}

.required-star{
    color: red;
}

.list-none{
    list-style: none;
}
.font-bold-600{
    font-weight: 600;
}

.mb-10{
    margin-bottom: 10px;
}
.mb-20{
    margin-bottom: 20px;
}
.mt-10{
    margin-top: 10px;
}
.mt-10-imp{
    margin-top: 10px !important;
}
.mt-20{
    margin-top: 20px;
}
.ml-5{
    margin-left: 5px;
}
.mr-5{
    margin-right: 5px;
}
.mr-5-imp{
    margin-right: 5px !important;
}
.mr-10{
    margin-right: 10px;
}
.mr-10-imp{
    margin-right: 10px !important;
}
.pd-10{
    padding: 10px;
}
.pd-15{
    padding: 15px;
}
.pd-20{
    padding: 20px;
}
.pd-10-imp{
    padding: 10px !important;
}
.pd-left-20{
    padding-left: 20px;
}
.pd-left-30{
    padding-left: 30px;
}

.gap-5{
    gap:5px;
}
.gap-10{
    gap:10px;
}
.gap-15{
    gap:15px;
}

.pointer{
    cursor: pointer;
}