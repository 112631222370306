.global-table {
    flex: 0.5;
    list-style-type: none;
}

.global-table:hover {
    background-color: transparent;
}

.global-table-data {
    border: none;
    font-size: 12px;
    padding-bottom: 0.5rem;
}

.global-table-data button{
    border:none;
    width: 15px;
    height: 15px;
    padding-right:0.3rem;
}

.geo-legend {
    display: flex;
    flex-direction: row;
}

.header-text {
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    background: #4a11a2;
    margin: 0;
    padding: 41px 0 24px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    color: #fff;
}

.global-map-wrapper {
    background: linear-gradient(#4911a2,#9211a2);
    color: #fff;
    overflow: auto !important;
    height: 300px ;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.global-map-wrapper-full {
    background: linear-gradient(#4911a2,#9211a2);
    color: #fff;
    overflow: auto !important;
    height: 100vh ;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

@media only screen and (max-width: 760px) {
    .geo-legend {
        flex-direction: column;
    }

    .global-table {
        flex: 1;
    }
}