.serve-type{
    border:1px solid #bdbdbd;
    border-radius: 10px;
    cursor: pointer;
    padding: 15px 20px;
}
.serve-type:hover{
    border-color: purple;
}
.serve-type h3, .serve-type p{
    margin:0;
}
.serve-type h3{
    margin-bottom: 5px;
}

.pagination-btns{
    margin-top:20px;
}

.selected-serve-type{
    background-color: rgb(243, 161, 243);
}

.form-section-heading{
    background-color: #eeeeee;
    margin-top: 20px;
    margin-bottom: 20px;
    padding:10px;
}

.quota-shown-box{
    /* flex-direction: row-reverse; */
}
.quota-shown-box .input-label{
    /* width:50%; */
    /* border:1px solid; */
}