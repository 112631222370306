table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  font-size: 10px;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
