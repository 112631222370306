button.close-icon {
    /* background-color: red; */
    position: absolute;
    right: 0;
    top: 0;
    color: white;
}
button.close-icon:hover {
    background-color: rgb(186, 5, 186);
}

div.box {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background: rgb(156 6 149) !important;
    position: relative !important;
    width: 800px;
    height: 80vh;
}
.pie-chart{
    width: 500px;
    height: 500px;
}