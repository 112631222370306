.maxMin{
    position: absolute;
    top: 0;
    right: 22px;
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    color: #fff;
    /* background: #fff; */
}

.min{
    /* padding: 11px;
    cursor: pointer; */
    padding: 1px 11px;
    border-radius: 20%;
    background: #a33eb5;
    cursor: pointer;
}
.max{
    /* padding: 11px;
    cursor: pointer */
    padding: 1px 11px;
    border-radius: 20%;
    background: #a33eb5;
    cursor: pointer;
}

.labelcolor{
    color: #7d11a2;
    font-weight: 700;
    font-size: 15px;
}