.campaign-box{
    background: #ffffff;
    box-shadow: 0.8rem 0.8rem 0.8rem rgba(77, 77, 77, 0.1);
}
.campaign-list > ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}
.campaign-list > ul > li{
    flex: 1 0 10%;
    padding: 1.6rem 2rem;
}
.campaign-box-content{
    display: flex;
    align-items: flex-start;
}
.campaign-box-content .icon{
    flex-shrink: 0;
    margin-right: 16px;
}
.campaign-box-content .icon svg{
    width: 40px;
    height: 40px;
    display: block;
}
.campaign-box-content .icon svg path{
    /* fill: rgb(236, 65, 236); */
    /* color: rgb(236, 65, 236); */
}
.campaign-box-content .txt .value-container{
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    padding-bottom: 8px;
}
.campaign-box-content .txt .value-label{
    font-size: 12px;
    font-weight: 600;
    color: rgba(0,0,0,.5);
    text-transform: uppercase;
}