table {
  width: 300px;
}

td,
th {
  padding: 0;
  border: 1px solid rgb(0, 0, 0);
}

tr:hover {
  background-color: #ddd;
}
.maintable {
  margin-top: 20px;
  overflow: scroll;
}

.maintable tr th {
  background-color: #000000;
  padding: 5px;
  color: white;
}
.maintable tr td {
  padding: 25px;
  color: rgb(0, 0, 0);
}
.belowtable {
  margin-top: 20px;
  overflow: scroll;
}
.belowtable tr td {
  padding: 5px;
  color: rgb(0, 0, 0);
}
.belowtable tr th {
  background-color: #000000;
  padding: 5px;
  color: white;
}
