body {
  background: #f4f3f8;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
html {
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #6c757d;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6c757d;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #6c757d;
}

.leaflet-popup {
  opacity: 1;
  transform: translate3d(389px, 163px, 0px);
  bottom: -6px !important;
  left: -125px !important;
  width: 250px !important;
}

.leaflet-popup-close-button {
  color: #ffffff !important;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background: #76117a !important;
  color: #000000 !important;
  box-shadow: 0 3px 14px rgb(0 0 0 / 40%) !important;
  border: 5px solid #000;
}

.MuiList-root.MuiMenu-list.MuiList-padding .MuiCheckbox-root {
  display: none !important;
}

.MuiListItem-root.Mui-selected {
  border: 1px solid rgba(188,6,233, 0.3) !important;
  background-color: rgba(188,6,233, 0.3) !important;
}

.MuiListItem-root.Mui-selected:hover {
  background: rgba(188,6,233, 0.3) !important;
}

.select-checkbox .MuiCheckbox-root{
  display: none !important;
}

.select-checkbox .MuiFormControlLabel-root{
  margin: 3px 0;
}

.select-checkbox .Mui-checked + .MuiTypography-body1{
  background: rgba(188,6,233, 0.3) !important;
  color: #000000 !important;
}

.select-checkbox .MuiTypography-body1{
  padding: 5px;
}

.select-checkbox-modal .MuiCheckbox-root{
  display: none !important;
}

.select-checkbox-modal .MuiFormControlLabel-root{
  margin: 3px 0;
}

.select-checkbox-modal .Mui-checked + .MuiTypography-body1{
  background: rgba(188,6,233, 0.3) !important;
  color: #ffffff !important;
}

.select-checkbox-modal .MuiTypography-body1{
  padding: 5px;
}

.addmore-checkbox .MuiIconButton-label{
  display: none !important;
}

.addmore-checkbox-checked .MuiIconButton-label{
  display: none !important;
}

.addmore-checkbox-checked input{
  background: rgba(188,6,233, 0.3) !important;
  color: #000000 !important;
}

.makeStyles-accordion-66 {
  min-height: 50px !important;
  max-width: 85% !important;
}
.makeStyles-accordion-281{
  min-height: 0px !important;
}
.accordion-box {
  min-height: auto !important;
}

.mt-3 {
  margin-top: 1rem !important;
}