.targeting-section-modal  *{
    margin:0;
}
.targeting-section-modal{
    /* width:90%; */
    /* max-width: 800px; */
    /* min-height: 400px; */
    /* max-height: 600px; */
    overflow-y: auto;
    background-color: white;
    /* padding:10px 20px; */
    padding-bottom: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    inset: 40px;
}

.targeting-section-modal .close-button{
    padding:5px 10px;
    display: flex;
    width:unset;
    min-width: unset;
    height:unset;
    min-height: unset;
    color: black;
}
.modal-main-content{
    flex:1;
    height: 600px;
    overflow-y: auto;
}
.targeting-section-modal .sticky-action{
    position: sticky;
    bottom:0;
    background-color: rgb(245, 245, 245);
    padding: 10px 20px;
}

/* data-target section css */

.base-button-adder{
    border: 1px solid rgb(194, 194, 194) !important;
}
.base-button-adder:first-child{
    padding: 12px;
    /* background-color: purple; */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: none !important; 
}
.base-button-adder:nth-child(2){
    padding: 12px;
    background-color: rgb(255, 255, 255);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: none !important; 
}
.base-button-adder.active-operation{
    background-color: purple;
    color: white;
}


/* data-rule */
.data-rule .delete-btn{
    display: none;
}
.data-rule:hover .delete-btn{
    display: unset;
    cursor: pointer;
}