.App {
  text-align: center;
}
#root{
  height: 100%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.homeDiv {
  /* background-image: url("./assets/chart.jpg"); */
  /* background-image: url("./assets/logo/landingImage.png"); */
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.homeImage {
  height: 85vh;
  position: relative;
  top: -165px;
  float: right;
  display: flex;
}

#dummyBlue {
  background-image: url("./assets/blueDummy.png");
  background-position: center;
  background-size: cover;
  width: 45%;
  height: 200px;
}

#dummygreen {
  background-image: url("./assets/greenDummy.png");
  background-position: center;
  background-size: cover;
  width: 45%;
  height: 200px;
}
th,
td {
  white-space: nowrap;
}

.nice-dates-navigation,
.nice-dates-day {
  color: #111;
}
.nice-dates-popover {
  box-shadow: none;
  border: 1px solid #ddd;
  border-radius: 2px;
  max-width: 400px !important;
  transition: none;
}

.upload-csv-file {
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  /* line-height: 1.5; */
  outline: none;
  background-color: white;
  border: 2px solid rgb(229, 229, 229);
  color: black;
  border-radius: 23px;
}

.upload-image-file {
  border-color: #7c7777;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #86888b;
  appearance: none;
  border-radius: 5px;
}
.upload-csv-file-input {
  border-color: #7c7777;
  margin-bottom: 12px;
  display: block;
  width: 250px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #86888b;
  appearance: none;
  border-radius: 5px;
}
.digital-upload-csv-file-input {
  border-color: #7c7777;
  margin-bottom: 12px;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #86888b;
  appearance: none;
  border-radius: 5px;
}

.pie-canvas canvas {
  max-height: 250px !important;
}

.select-box select {
  height: 36px;
  padding-top: 1;
  border-radius: 5px;
  background-color: #ffffff;
  border: 2;
  margin: 0;
  display: inline-flex;
  padding: 8px;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  height: 36px;
  color: grey;
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
}
.message-popup {
  justify-content: center;
  position: relative;
}

.message-popup {
  width: 50%;
  height: 250px;
  top: 15%;
  left: 30%;
  border-radius: 115px;
  background-color: rgb(135 17 162);
  color: white;
  padding: 30px 50px;
}

.message-popup:after {
  content: "";
  position: absolute;
  background-color: rgb(135 17 162);
  top: 98%;
  left: 75%;
  width: 6%;
  height: 20px;
  -webkit-transform: rotate(-55deg);
  transform: rotate(-45deg);
}
.message-popup:before {
  content: "";
  position: absolute;
  background-color: rgb(135 17 162);
  top: 96%;
  left: 72%;
  width: 7%;
  height: 24px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.leaflet-marker-icon
  .marker-cluster-custom
  .leaflet-zoom-animated
  .leaflet-interactive {
  margin-left: -10px !important;
  margin-top: -10px !important;
}

.marker-cluster-custom {
  color: #ffffff !important;
  background-color: #000 !important;
  opacity: 0.5 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 100% !important;
}

.editor-description-box {
  width: 80%;
  border: 1px solid #6c757d;
  margin-bottom: 10px;
  margin-top: 10px;
  min-height: 100px;
}
.editor-description-box .rdw-editor-main {
  padding: 10px;
}
.rdw-option-wrapper img {
  height: 10px !important;
  width: 10px !important;
}
/* .rdw-image-imagewrapper img {
  max-width: 50px;
  max-height: 50px;
} */
.accordion-box-media .MuiAccordionSummary-content {
  justify-content: space-between;
}
.bar-graph-accordian {
  display: block !important;
}
.accordion-demographics {
  justify-content: center !important;
  width: 100% !important;
  align-items: center !important;
  margin-top: 10px;
}
.accordion-demographics .MuiGrid-root.text-center.MuiGrid-container {
  justify-content: center !important;
}
.main-participant {
  flex-wrap: wrap;
  align-items: flex-start !important;
}

.main-participant .accordion-demographics{
  width: 50% !important;
  padding-bottom: 30px !important;
}

.main-participant textarea {
  background-color: transparent !important;
  border: none !important;
  color: #000000;
  line-height: 1.4;
  min-height: 110px;
  overflow: auto !important;
}

.main-participant textarea::placeholder , .main-participant textarea:-ms-input-placeholder , 
.main-participant textarea::-webkit-input-placeholder {
  color: #000000 !important;
  opacity: 1 !important;
}

.main-participant textarea:focus, .main-participant textarea:active {
  background-color: transparent !important;
  border: none !important;
  outline: none;
}
.main-participant .fieldset {
  background-color: transparent !important;
}
.main-participant .accordion-demographics fieldset {
  background-color: transparent !important;
}

.main-participant .accordion-demographics .card.message-content {
  padding: 15px;
  min-height: 210px;
  margin: 10px;
  max-width: 350px;
  width: 100%;
  margin: 0 auto 10px;
}

.dropdown-color div .css-1s2u09g-control{
  border-color: grey;
}

.MuiPopover-paper{
  max-height: 350px!important;
}

/* quill editor css  */
.quill .ql-toolbar.ql-snow{
  border: 1px solid #F1F1F1;
}
.quill .ql-editor.ql-blank{
  min-height: 100px;
}
