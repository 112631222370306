/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.example {
  transition: transform 0.4s; /* Animation */
  margin: 0 auto;
}

.example:hover {
  transform: scale(1.1); /* (150% zoom)*/
}
.aascroll::-webkit-scrollbar {
  display: none;
}
.longShadow {
  --dncol: rgb(39, 209, 90);
  text-shadow: var(--dncol) 1px 1px, var(--dncol) 2px 2px, var(--dncol) 3px 3px,
    var(--dncol) 4px 4px, var(--dncol) 5px 5px, var(--dncol) 6px 6px,
    var(--dncol) 7px 7px, var(--dncol) 8px 8px, var(--dncol) 9px 9px,
    var(--dncol) 10px 10px, var(--dncol) 11px 11px, var(--dncol) 12px 12px,
    var(--dncol) 13px 13px, var(--dncol) 14px 14px, var(--dncol) 15px 15px,
    var(--dncol) 16px 16px, var(--dncol) 17px 17px, var(--dncol) 18px 18px,
    var(--dncol) 19px 19px, var(--dncol) 20px 20px, var(--dncol) 21px 21px,
    var(--dncol) 22px 22px, var(--dncol) 23px 23px, var(--dncol) 24px 24px,
    var(--dncol) 25px 25px, var(--dncol) 26px 26px, var(--dncol) 27px 27px,
    var(--dncol) 28px 28px, var(--dncol) 29px 29px, var(--dncol) 30px 30px,
    var(--dncol) 31px 31px, var(--dncol) 32px 32px, var(--dncol) 33px 33px,
    var(--dncol) 34px 34px, var(--dncol) 35px 35px, var(--dncol) 36px 36px,
    var(--dncol) 37px 37px, var(--dncol) 38px 38px, var(--dncol) 39px 39px,
    var(--dncol) 40px 40px, var(--dncol) 41px 41px, var(--dncol) 42px 42px,
    var(--dncol) 43px 43px, var(--dncol) 44px 44px, var(--dncol) 45px 45px,
    var(--dncol) 46px 46px, var(--dncol) 47px 47px, var(--dncol) 48px 48px,
    var(--dncol) 49px 49px, var(--dncol) 50px 50px, var(--dncol) 51px 51px,
    var(--dncol) 52px 52px, var(--dncol) 53px 53px, var(--dncol) 54px 54px,
    var(--dncol) 55px 55px, var(--dncol) 56px 56px, var(--dncol) 57px 57px,
    var(--dncol) 58px 58px, var(--dncol) 59px 59px, var(--dncol) 60px 60px,
    var(--dncol) 61px 61px, var(--dncol) 62px 62px, var(--dncol) 63px 63px,
    var(--dncol) 64px 64px, var(--dncol) 65px 65px, var(--dncol) 66px 66px,
    var(--dncol) 67px 67px, var(--dncol) 68px 68px, var(--dncol) 69px 69px,
    var(--dncol) 70px 70px, var(--dncol) 71px 71px, var(--dncol) 72px 72px,
    var(--dncol) 73px 73px, var(--dncol) 74px 74px, var(--dncol) 75px 75px,
    var(--dncol) 76px 76px, var(--dncol) 77px 77px, var(--dncol) 78px 78px,
    var(--dncol) 79px 79px, var(--dncol) 80px 80px, var(--dncol) 81px 81px,
    var(--dncol) 82px 82px, var(--dncol) 83px 83px, var(--dncol) 84px 84px,
    var(--dncol) 85px 85px, var(--dncol) 86px 86px, var(--dncol) 87px 87px,
    var(--dncol) 88px 88px, var(--dncol) 89px 89px;
}

.MuiTypography-h5 {
  font-size: 0.9rem !important;
}

.graph-checkbox {
  justify-content: flex-start;
  display: grid;
}
.graph-checkbox .MuiCheckbox-root {
  color: white;
}

.graph-table td,
th {
  border: 0px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-top: 1px solid rgba(224, 224, 224, 1);
}
.graph-table :hover {
  background-color: unset !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}
.badge {
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  padding: 5px 15px;
  width: 100%;
}
.main-title {
  margin: 10px;
  overflow: hidden;
  display: block;
  padding-top: 10px;
}
.main-boxses {
  margin: 20px;
}
.card.message-content {
  padding: 15px;
  min-height: 210px;
  min-width: 210px;
  margin: 10px;
}
.bg-secondary {
  background-color: #6c757d !important;
}
.bg-color {
  background-color: rgb(85 31 171) !important;
}
.bg-dark {
  background-color: #212529 !important;
}
/* .card.message-content p {
  max-height: 150px;
  overflow-y: auto;
} */
.bg-warning {
  background-color: #ffc107 !important;
}
.bg-success {
  background-color: #198754 !important;
  margin-top: 15px;
}
.mt2 {
  margin-top: 15px;
}
.badge-shape {
  position: relative;
}
.badge-shape:before {
  content: "";
  right: 0px;
  bottom: -47px;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 50px solid #ffc107;
  position: absolute;
}
.card.message-content.bg-warning.badge-shape {
  min-height: 210px;
}
.card {
  border-radius: 0.25rem;
}
.report-date {
  font-weight: 700;
  font-size: 12px;
  display: block;
  text-align: right;
  margin-top: 10px;
}
.reportby-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 120px;
  padding: 10px 15px;
  margin: 0;
}
.title-text h2 {
  text-align: center;
  display: block;
}
.title-icon .badge {
  margin-bottom: 20px;
}
.text-center {
  text-align: center;
}
.d-block {
  display: block;
}

.reportby-date h6 {
  font-weight: 700;
  font-size: 12px;
  padding: 10px;
  margin: 0;
}
.reportby-date h6 {
  width: 45%;
}
.graph-main {
  margin-top: 55px;
}
.main-boxses button.MuiButtonBase-root {
  /* background-color: #272533; */
  padding: 15px 20px;
  color: #fff;
  height: 120px;
}

.summary-btn {
  width: 100%;
  margin-bottom: 10px !important;
}
.image {
  background-color: #ffffff;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}
.text-left span {
  text-align: left !important;
  display: block;
}

.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}

.bg-purple {
  background-color: #6e258b !important;
  border: 3px solid #904cab !important;
}

.bg-light-purple {
  background-color: #f57fed !important;
  border: 3px solid #ffa3f9 !important;
}

.bg-dark-purple {
  background-color: #a825a0 !important;
  border: 3px solid #c82ebf !important;
}

.details-popup {
  background-color: rgb(156, 6, 149);
  color: white;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  height: 50%;
  width: 50%;
  border: 1px rgb(0, 0, 0);
  padding-bottom: 30px;
}

.details-popup .title-text {
  padding: 15px 40px;
  font-size: 18px;
}

.details-popup .title-text h2 {
  text-align: center;
  display: block;
  font-size: 36px;
  line-height: 1.2;
  margin-top: 0;
}
.bar-graph-answer .highcharts-container {
  max-width: 381px !important;
  width: 100% !important;
  display: block;
  height: 550px !important;
}
.bar-graph-answer {
  text-align: center;
  display: block;
  margin: 0 auto 50px auto;
  overflow: hidden;
  max-height: 400px;
  height: 100%;
  width: 30%;
  padding-bottom: 40px;
}
.bar-graph-answer svg {
  width: 50%;
  height: 510px;
  margin: 0 auto;
  display: block;
}
.bar-graph-answer-pyramid svg {
  width: 55%;
  height: 47%;
}

.bar-graph-answer.bar-graph-answer-pyramid b {
  max-width: 250px;
  width: 100%;
  display: inline-block;
}

.modal-boxes {
  padding: 50px 0;
}
.card {
  text-align: center;
  position: relative;
}
.card .icon img {
  max-width: 80px;
  width: 100%;
}
.card .card-body {
  padding: 70px 30px 30px 30px;
}
.card .icon {
  max-width: 80px;
  width: 100%;
  background-color: #bae42d;
  border-radius: 100%;
  text-align: center;
  margin: 0 auto;
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  overflow: hidden;
}
.bg-green {
  background-color: #99c600;
  color: #ffffff;
}

.badge-shape2 {
  background-color: #99c600;
  color: #ffffff;
  border-radius: 25px;
  position: relative;
}
.badge-shape2:before {
  content: "";
  left: 50px;
  bottom: -35px;
  width: 60px;
  height: 55px;
  border-left: 100px solid transparent;
  border-right: 20px solid transparent;
  border-top: 10px solid #99c600;
  border-radius: 0px 50px 20px 160px;
  position: absolute;
  display: block;
  background-color: #99c600;
  z-index: -9;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg) skewY(14deg) !important;
}
.modal-boxes .card p {
  max-height: 160px;
  overflow: auto;
}
.badge-shape-round {
  border-radius: 60%;
}
.badge-shape-round.badge-shape2:before {
  content: "";
  right: 70px;
  left: auto;
  background-image: url(/static/media/border-bottom-shape.ced88228.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  border: 0;
  background-color: transparent;
  bottom: -42px;
  -webkit-transform: rotateY(195deg);
  transform: rotateY(195deg);
}

.fold-card {
  position: relative;
  background-color: rgb(146, 17, 162) !important;
  color: #ffffff !important;
  min-height: 180px;
  width: 150px;
  overflow: auto !important;
  border-radius: 15px !important;
}
/* folding */
.fold-card::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-style: solid;
  border-width: 0 20px 20px 0;
  border-color: #ddd #344;
  transition: all ease 0.5s;
}
.fold-card:hover::before {
  border-width: 0 25px 25px 0;
  border-color: #eee #344;
}
.fold-card .MuiCardContent-root {
  max-height: 180px;
  overflow: auto;
  padding-top: 20px;
}

.border-widget-btn span.MuiFormControlLabel-label {
  border: 1px solid #8663bd;
  margin-bottom: 5px;
  padding: 5px 10px;
}
.MuiPaper-root .MuiAccordion-root{
  min-height: 10px !important;
}
.decia-report-box .MuiGrid-root {
  justify-content: center;
  z-index: 9999999990;
}

.details-popup-graph {
  background-color: rgb(156, 6, 149);
  color: white;
  overflow: auto;
  position: absolute;
  border: 1px solid #000000;
  padding: 35px;
  max-width: 80%;
  width: 100%;
  left: 10%;
  top: 45px;
  bottom: 45px;
}
.custom-popup-new {
  display: flex;
  justify-content: center;
  padding: 15px;
  flex-wrap: wrap;
}
.content-wrapper-new {
  width: 100%;
  height: 200px;
  overflow-y: auto;
}
.chart-wrapper-new {
  width: 100%;
}


.graph-grid-wrapper {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding-bottom: 15px;
}
.graph-wrapper {
  width: 50%;
  padding: 15px;
}
.graph-wrapper h4.graph-title {
  margin: 0 0 15px 0;
  font-size: 10px;
}
.graph-content{
  width: 50%;
  padding: 15px;
}
.graph-content h2{
  margin:  0 0 25px 0;
  text-align: left;
  font-size: 16px;
}
.graph-content p {
  max-height: 175px;
  padding-right: 15px;
  /* overflow-y: auto; */
  text-align: justify;
}
.graph-content.graph-content-normal {
  width: 100%;
}
.graph-content.graph-content-normal p{
  max-height: initial;
  word-break: break-word;
}
ul.details-listing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
ul.details-listing li{
  width: calc(50% - 10px);
  border: 1px solid rgba(255,255,255,0.2);
  margin: 5px;
}
.pyramid-wrapper .highcharts-container {
  height: 250px !important;
}

.details-popup-graph .clos-icon {
  position: absolute;
  right: 0;
  top: 10px;
}
.details-popup-graph .clos-icon .MuiButton-label{
  color: #ffffff;
}

ul.details-listing li.full-width {
  width: 100%;
}

ul.details-listing li.full-width-content .graph-wrapper {
  width: 100%;
}

.leaflet-popup-content-wrapper{
  border-width: 2px;
}

.css-26l3qy-menu{
  z-index: 9999!important;
  color: #000000;
}

.MuiCircularProgress-root{
  position: absolute;
  z-index: 999;
  top: 58%;
  left: 57%;
}

.color-dot{
    list-style: none;
}
.color-dot li{
    cursor: pointer;
}

.color-dot li .media-dot{
  padding: 1px 9px;
  border-radius: 50%;
}
.color-dot li .media-name{
  font-size: 12px;
  padding-left: 5px;
}
.color-dot-wrapper{
    position: absolute;
    top: 55%;
    left: 0%;
}
.radio-dots {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
.radio-dots ul {
  padding: 0 10px;
}
.color-dot-wrapper .MuiGrid-root .MuiPaper-root {
  margin: 0 auto;
}
.search-total-bar h4 {
  display: block !important;
  width: 100%;
  text-align: center;
}
.search-total-bar {
  margin-bottom: 30px;
  display: block;
  width: 100%;
  overflow: hidden;
}
.search-select {
  margin-bottom: 40px;
  display: flex;
  width: 100%;
  overflow: hidden;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 10px;
  left: auto;
  justify-content: flex-end;
}

.deciaReport {
  /* display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px; */
  overflow: auto;
  height: calc(100% - 116px);
}
.report-height-100{
  height: 100%;
}
.report-box > div{
  height: 100%;
  max-height: 437px;
}
.full-block.report-box > div{
  max-height: 100%;
  height: 72vh;
}
.report-box .MuiCardContent-root{
  height: 100%;
}
.report-list-item{
  display: flex;
  align-items: center;
  padding: 0 16px 10px;
}
.report-list-item > img{
  width: 48px;
  margin-right: 16px;
  flex-shrink: 0;
}

.deciaReport .report {
  cursor: pointer;
}
.sidebar-logo-list > li > a{
    margin: 0 0.3rem;
}

.max-min-box{
  position: absolute;
  right: 16px;
  top: 16px;
}
.box-title {
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  padding: 41px 0 24px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  color: #fff;
}

.swiper-button-prev, .swiper-button-next{
  color: #ffffff !important;
  z-index: 12 !important;
}
.campaign-id-input-container input,
.line-id-input-container input{
  border-radius: 11111px;
  padding: 12px 10px;
  text-align: center;
  border: none;
  width: 100%;
  font-size: 14px;
}

.diversity_dividend{
  display: flex;
  justify-content: space-between;
  gap: 46px;
  align-items: center;
  padding: 0 20px;
}

.diversity_campigns_btn{
  border-radius: 4px;
  width: 268px;
  padding: 0 12px !important;
  height: 36px;
  font-size: 14px;
  border: none;
  background-color: #ffffff;
  color: #000000;
  font-weight: 700;
  cursor: pointer;
  margin-top: 8px;
}

/* .diversity_campigns_btn:hover{
  color: #4A11A2;
  padding: 0 13px !important;
} */

.diversity_dividend_container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #ffffff;
  padding: 2px 4px;
  border-radius: 12px;
}

.emission_graph_cont{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* gap: 16px; */
  padding: 8px 12px;
}

.emission_card{
  background-color: #ffffff;
  color: #000000;
  border-radius: 8px;
  width: 184px !important;
  text-align: center;
  display: flex;
  /* margin-left: 32px; */
  flex-direction: column;
  justify-content: space-between;
  /* margin-top: 8px; */
  height: 340px;
  /* gap: 8px; */
  padding: 8px;
}

.emission_card *{
  margin: 2px;
  margin-left: 2px !important;
}

.add_card_margin *{
  margin: 2px;
  margin-left: 2px !important;
}

.electronics_emission_card{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 100%;
  margin-bottom: 12px;
}

.electronic_img{
  height: 36px;
  width: 32px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background-color: #F5C6EC;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emission_data_card{
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  align-items: start;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
}

.impact_score_card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 142px;
  width: 182px;
  color: #ffffff;
  gap: 8px;
  margin-right: 4px;
}

.impact_score_card *{
  margin: 0;
}

.impact_score{
  font-size: 4.8rem;
  font-weight: 700;
}

.pie_chart_card_minimized{
  /* color: #ffffff; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 104px;
  height: 180px;
  gap: 0;
}

.pie_chart_card_maximized{
  /* color: #ffffff; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 240px;
}

.pie_chart_card_minimized *{
  margin: 0;
  padding: 0;
}

.impact_question{
  color: #000000;
  text-align: center !important;
  font-size: 18px;
  font-weight: 700;
  max-width: 20%;
}

.haleon_si{
  color: #000000;
  text-align: center;
  max-width: 20%;
}

.cpm_breakdown{
  border: 4px solid #ffffff;
  display: flex;
  color: #ffffff;
  padding: 12px 8px;
  gap: 16px;
  align-items: center;
}

.cpm_tags_cont{
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.cpm_tag_outer{
  display: flex;
  flex-direction: row;
  border-radius: 60px;
  background-color: #6CE5E8;
  padding-right: 12px;
  gap: 8px;
}

.cpm_tag_inner{
  text-align: center;
  border-radius: 60px;
  background-color: #41B8D5;
  padding: 2px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.haleon_data_card{
  display: flex;
  flex-direction: row;
  gap: 22px;
  align-items: center;
  border: 4px solid #ffffff;
  padding: 2px 18px;
  border-radius: 8px;
  background: #ffffff;
  margin-bottom: 16px;
  width: 100%;
  max-width: 1072px;
  flex-wrap: wrap;
}


.sp_haleon_card_maximized{
  display: flex;
  flex-direction: row;
  gap: 22px;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  background: #ffffff;
  width: 100% !important;
  max-width: 59%;
  height: 264px;
}

.sp_haleon_card_minimized{
  display: flex;
  flex-direction: row;
  gap: 22px;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  background: #ffffff;
  width: 100% !important;
  max-width: 1064px;
  height: 264px;
}

/* responsive */

@media (max-width: 1440px) {
  .modal-boxes .card p {
    font-size: 16px;
  }
}

@media (max-width: 1366px) {
  .card .card-body {
    padding: 30px 10px 30px 10px;
  }
  .modal-boxes .card p {
    font-size: 14px;
  }
  .card .icon {
    max-width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width:1199px) {
  ul.details-listing li{
    width: 100%;
  }
  /* .graph-content p {
    max-height: 200px;
  } */
}

@media (max-width: 991px) {
  .badge {
      font-size: 18px;
  }
  
}
@media (max-width: 890px) {
  /* .reportby-date {
    min-height: 171px;
  } */
  .badge {
    font-size: 16px;
  }
}
@media(max-width:767px){
  .decia-report-box .MuiGrid-container {
    display: block;
  }
  .decia-report-box .MuiGrid-grid-sm-6 {
      flex-grow: 0;
      max-width: 100%;
      flex-basis: 100%;
  }
  .decia-report-box .MuiGrid-grid-sm-4 {
      max-width: 100%;
      flex-basis: 100%;
  }
}
@media screen and (max-width: 992px) {
  .deciaReport {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .deciaReport {
    grid-template-columns: repeat(3, 1fr);
  }
}

.graph-content > p {
  max-height: 175px;
  /* overflow-y: auto; */
  text-align: justify;
  display: block;
}

.graph-content{
  display: flex !important;
  flex-direction: column;
  gap: 10px;
}

.graph-grid-wrapper img{
  max-width: 100%;
}

.float-form-btn{
  background: #6511A2;
  border-radius: 12px;
  color: #ffffff;
  margin-bottom: 18px;
  padding: 8px 16px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.ci-icon-filterbar{
  width: 85%;
  margin: 20px auto;
  position: absolute;
  top: 0;
  /* left: 0; */
}

.gl-data{
  display: flex;
  gap: 8px;
  padding-top: 8px;
  flex-wrap: wrap;
}

.em_breakdown_card{
  background-color: #ffffff;
  color: #000000 !important;
  border-radius: 8px;
  width: 436px !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* margin-left: 32px; */
  justify-content: space-evenly !important;
  align-items: center !important;
  /* margin: 8px 12px 8px 0; */
  /* margin-top: 8px;; */
  height: 340px;
}

.em_breakdown_card *{
  margin: 2px;
  margin-left: 2px !important;
}

.custom-tooltip{
  background-color: white;
  border-radius: 12px;
  padding: 8px 12px;
  opacity: 0.8;
  color: #000000;
  box-shadow: 0px 0px 4px 0px #000000;
}

.mini_width_full{
  width: 100% !important;
  /* max-width: 500px; */
}

.gl-dash-wrapper {
  color: #fff;
  overflow: auto !important;
  height: 336px ;
}

.gl-dash-wrapper-full {
  color: #fff;
  overflow: auto !important;
  height: 80vh ;
}

.csv_pie_card{
  background-color: #ffffff;
  color: #000000 !important;
  border-radius: 8px;
  width: 262px;
  height: 280px;
  text-align: center;

}

.csv_upload_card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 142px;
  /* width: 185px; */
  width: 262px;
  gap: 8px;
  /* margin-right: 4px; */
  background: #ffffff;
  border-radius: 8px;
  height: 280px;
  color: #000000;
}

.bar_graph_card_minimized{
  background-color: #ffffff;
  color: #000000 !important;
  border-radius: 8px;
  width: 240px;
  height: 280px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly
}

.bar_graph_card_maximized{
  background-color: #ffffff;
  color: #000000 !important;
  border-radius: 8px;
  width: 240px;
  height: 280px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly
}

.em_br_btns{
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px;
}

.em_br_btns *{
  margin: 0 !important;
  /* border-right: 0 !important; */
  border: 1px solid #8111a2;
  background: #ffffff;
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 12px;
  cursor: pointer;
}

.em_br_btns button:hover{
  background: #8111a2;
  color: #ffffff;
}

.em_br_btns_active{
  background: #8111a2;
  color: #ffffff;
}

/* .em_br_btns button:first-child{
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-left: 4px;
}

.em_br_btns button:last-child{
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-right: 1px solid #8111a2 !important;
  margin-right: 4px;
} */

.sp_bar_graph_card_minimized{
  background-color: #ffffff;
  color: #000000 !important;
  border-radius: 8px;
  width: 400px;
  height: 240px !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly
}

.sp_bar_graph_card_maximized{
  background-color: #ffffff;
  color: #000000 !important;
  border-radius: 8px;
  width: 462px !important;
  height: 264px !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly
}

.map_container_em{
  width: 472px;
  height: 292px;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  margin-top: 16px !important;
}


.sp_em_breakdown_card{
  background-color: #ffffff;
  color: #000000 !important;
  border-radius: 8px;
  width: 532px;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* margin-left: 32px; */
  justify-content: space-evenly !important;
  align-items: center !important;
  /* margin: 8px 12px 8px 0; */
  /* margin-top: 8px;; */
  height: 364px;
}

.sp_em_breakdown_card *{
  margin: 2px;
  margin-left: 2px !important;
}

.greenAd_options{
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 4px !important;
    text-indent: 50px;
}

.intelligence_heading{
  display: flex;
  flex-direction: row;
}

.ci_intelligence_h1{
  border-right: 1px solid #ffffff;
  width: 52.3% !important;
  margin: 0;
}

.gpt_intelligence_h1{
  border-left: 1px solid #ffffff;
  width: 50% !important;
  margin: 0;

}

.intelligence_wrap_cont{
  display: flex;
  flex-direction: row;

}

.ci_intelligence_cont{
  margin: 0;
  width: 50% !important;
  height: 100%;
  border-right: 1px solid #ffffff;  
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
}

.gpt_intelligence_cont{
  margin: 0;
  width: 50% !important;
  padding: 0 8px;
  border-left: 1px solid #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
}

.csv_upload_label{
  cursor: pointer;
}

.csv_upload_btn{
  background: #8111a2;
  color: #ffffff;
  border-radius: 12px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.intelligence_conts{
  width: 100%;
  height: 46vh;
  max-height: 46vh;
  display: flex;
  flex-direction: row;
  /* gap: 8px; */
}

.intelligence_logos{
  max-width: 240px;
}

.ai_response_cont{
  /* height: 100%; */
  max-height: calc(43vh - 68px);
  min-height: calc(43vh - 72px);
  /* max-height: 100%; */
  /* flex-grow: 1; */
  /* height: 306px !important; */
}

.ai_response_cont p{
  height: 92%
}

.rotate180{
  transform: rotate(180deg);
}