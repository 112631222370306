.hide-before-element ::before{
}
.MuiAccordion-root:before{
    display: none;
}

.mb--10{
    margin-bottom: -10px;
}

.required_label{
    color:red;
}